import React, { Component } from "react";

//mobx

import { observer } from "mobx-react";

//Socket

import socketIOClient from "socket.io-client";
import newsocket from "../Stores/socketStore";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      roomnumber: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
    console.log("EVENT:", event.target.value);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.username && this.state.roomnumber) {
      this.props.history.replace("/wait/");
    }
  }

  render() {
    const { username, roomnumber } = this.state;

    return (
      <div
        style={{
          backgroundImage:
            "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQpVDq-4RKTLbh9JaXdk37wbhTYt4xPY7xf3If5ldYSvRAMpRX)",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          overflow: "hidden",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <div
          className="mx-auto"
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center"
          }}
        >
          <div
            className="overlay"
            style={{
              justifyContent: "center",
              display: "flex",
              alignItems: "center"
            }}
          >
            <div className="text-center">
              <form onSubmit={this.handleSubmit}>
                <div className="text-center">
                  <label
                    className="mx-auto"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontFamily: "Comic Sans MS"
                    }}
                  >
                    User name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    value={username}
                    name="username"
                    placeholder="Username"
                    onChange={this.handleChange}
                  />
                </div>

                <div className="text-center">
                  <label
                    className="mx-auto"
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      marginTop: "15px",
                      fontFamily: "Comic Sans MS"
                    }}
                    onClick={() => newsocket.joinRoom(username, roomnumber)}
                  >
                    Room Code
                  </label>
                  <input
                    type="roomnumber"
                    className="form-control"
                    id="roomnumber"
                    value={roomnumber}
                    name="roomnumber"
                    placeholder="Roomcode"
                    onChange={this.handleChange}
                  />
                </div>

                <div
                  class="input-group mb-3"
                  style={{
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    fontFamily: "Comic Sans MS"
                  }}
                >
                  <div class="input-group-prepend">
                    <button
                      className="mx-auto"
                      class="btn btn-outline-secondary"
                      type="submit"
                      style={{
                        color: "black",
                        borderColor: "black",
                        backgroundColor: "#6b5b95",
                        fontWeight: "bold",
                        borderRadius: "70%",
                        display: "flex",
                        marginTop: "20px"
                      }}
                      onClick={() => newsocket.joinRoom(username, roomnumber)}
                    >
                      Enter
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(Login);
