import React, { Component } from "react";
import AnswerNo from "./Answer";

import socketIOClient from "socket.io-client";

import newsocket from "../Stores/socketStore";
import { observer } from "mobx-react";

class DisplayQ extends Component {
  render() {
    let answerscolors = [
      "btn btn-outline-primary",
      "btn btn-outline-secondary",
      "btn btn-outline-success",
      "btn btn-outline-danger",
      "btn btn-outline-info"
    ];

    let answersbuttons = newsocket.choices.map((answer, i) => (
      <AnswerNo
        answerincoming={answer}
        color={answerscolors[i]}
        index={i}
        history={this.props.history}
      />
    ));
    return (
      <div
        style={{
          backgroundImage:
            "url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRQpVDq-4RKTLbh9JaXdk37wbhTYt4xPY7xf3If5ldYSvRAMpRX)",
          height: "100vh",
          width: "100vw",
          position: "fixed",
          overflow: "hidden",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <div
          className="overlay"
          style={{
            justifyContent: "center",
            display: "flex",
            alignItems: "center"
          }}
        >
          <div className="mx-auto">
            <div className="text-center">
              <div
                className="card-body"
                style={{
                  justifyContent: "center",
                  backgroundColor: "	#E3E0E3",
                  width: "300px",
                  height: "600px"
                }}
              >
                <h5
                  className="card-title"
                  style={{
                    marginTop: "0px",
                    marginBottom: "20px"
                  }}
                >
                  <span>Choose One Answer ?</span>
                </h5>
                <div className="mx-auto">
                  <div
                    class="btn-group-vertical"
                    role="group"
                    aria-label="Basic example"
                  >
                    {answersbuttons}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DisplayQ;
