import { decorate, observable, computed } from "mobx";

class PicStore {
  constructor() {
    this.pics = [
      "https://media.giphy.com/media/l41lUR5urK4IAk3V6/giphy.gif",
      "https://media.giphy.com/media/l2Ject9fem5QZOyTC/giphy.gif",
      "https://media.giphy.com/media/DJRrbPdbi2Bgs/giphy.gif",
      "https://media.giphy.com/media/4JVTF9zR9BicshFAb7/giphy.gif",
      "https://media.giphy.com/media/l41YtZOb9EUABnuqA/giphy.gif",
      "https://media.giphy.com/media/26gJA9SSe4m54MYec/giphy.gif",
      "https://media.giphy.com/media/a1DcwyGtpLG9O/giphy.gif"
    ];
    this.indexno = 0;
  }

  getPic() {
    let picseq = this.indexno % this.pics.length;
    this.indexno++;

    return this.pics[picseq];
  }
}
const picstore = new PicStore();

export default picstore;
