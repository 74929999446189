import React, { Component } from "react";
import logo from "./logo.svg";

import "bootstrap/dist/css/bootstrap.min.css";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import "./App.css";

import { observer } from "mobx-react";

// Forms
import LoginForm from "./forms/LoginForm";

import PlsWaitForm from "./Components/PLSWait";
import QAForm from "./Components/Display Q&A ";


class App extends Component {
  render() {
    return (
      <Switch>
        <Redirect exact from="/" to="/enter/room/" />
        <Route path="/enter/room/" component={LoginForm} />;
        <Route path="/wait/" component={PlsWaitForm} />;
        <Route path="/room/Q&A/" component={QAForm} />;
      </Switch>
    );
  }
}

export default withRouter(observer(App));
