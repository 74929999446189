import { decorate, observable, action } from "mobx";
import socketIOClient from "socket.io-client";

class socketStore {
  constructor() {
    this.username = "";
    this.code = "";
    this.mathobj = false;
    this.choices = [];
    this.answer = "";
    this.socket = socketIOClient("https://socket.reemcantmath.com");
  }

  joinRoom(username, room) {
    this.code = room;
    this.socket.emit("user joined", {
      username: username,
      room: room
    });
  }

  setChoice(value) {
    console.log("TEST", value);
    this.answer = value;
    console.log(this.mathobj);
    if (value === this.mathobj.correct_choice) {
      this.socket.emit("correct", this.code);
      console.log(this.code);
      console.log("DING DING");
    } else {
      this.socket.emit("incorrect", this.code);
      console.log("DONG DONG");
    }
  }
}

decorate(socketStore, {
  username: observable,
  code: observable,
  mathobj: observable,
  choices: observable,
  answer: observable,
  socket: observable,
  joinRoom: action,
  getChoices: action,
  setChoice: action
});

const newsocket = new socketStore();
newsocket.socket.on("FromAPI", data => {
  newsocket.mathobj = data;
  newsocket.choices = data.choices;
});

export default newsocket;
