import React, { Component } from "react";
import MathJax from "react-mathjax-preview";
import newsocket from "../Stores/socketStore";

class Answer extends Component {
  render() {
    console.log("color", `btn btn-outline-${this.props.color}`);
    return (
      <div className="divider">
        <button
          class={`${this.props.color}`}
          type="submit"
          style={{
            color: "black",
            fontWeight: "bold",
            alignItems: "center",
            borderWeight: "bold",
            fontFamily: "Comic Sans MS",
            width: "200px",
            height: "80px",
            textAlign: "center",
            textDecoration: "none",
            display: "inline-block",
            fontSize: "16px"
          }}
          onClick={() => {
            newsocket.setChoice(this.props.index);
            this.props.history.push("/wait/");
          }}
        >
          <MathJax math={this.props.answerincoming} />
        </button>
      </div>
    );
  }
}
export default Answer;
