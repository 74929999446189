import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
import { observer } from "mobx-react";

//Stores

import PicStore from "../Stores/PicStore";
import newsocket from "../Stores/socketStore";

class Waiting extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true
    };
  }

  componentDidMount() {
    newsocket.socket.on("FromAPI", () => {
      console.log("STATE: ", this.state.loading);

      this.setState({ loading: false });
    });
  }

  getQuestions() {
    this.props.history.push("/room/Q&A/");
  }

  getPage() {
    if (this.state.loading) {
      return (
        <div
          style={{
            backgroundImage: `url(${PicStore.getPic()})`,
            backgroundColor: "white",

            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            border: "1px solid",
            backgroundSize: "contain",
            position: "fixed",
            overflow: "hidden",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0
          }}
        />
      );
    } else {
      return <div>Hello {this.getQuestions()}</div>;
    }
  }

  render() {
    return this.getPage();
  }
}
export default withRouter(observer(Waiting));
